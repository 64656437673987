// src/redux/reducers/newsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  news: [],
  isLoading: false,
  errorMessage: null,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    fetchNewsStart(state) {
      localStorage.removeItem('news')
      state.isLoading = true;
    },
    fetchNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload;
    },
    fetchNewsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
      localStorage.removeItem('news')
    },
  },
});

export const { fetchNewsStart, fetchNewsSuccess, fetchNewsFailure } = newsSlice.actions;
export default newsSlice.reducer;
