import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component

const Live = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {}, [dispatch]);

  if (!userData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        {/* Banner for web version */}
        <div className="hidden md:block relative h-96">
          <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
        </div>

        {/* Radiothon Live Video Player */}
        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <div className="flex justify-center">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full">
              <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-gray-100 mb-8 tracking-[.10em]">
                Radiothon <span className="text-blue-600">Live</span>
              </h2>
              <div className="flex justify-center overflow-auto">
                <iframe
                  src="//closeradio.tv/radiosurabhi/player.htm"
                  width="1920"
                  height="540"
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  webkitAllowFullScreen={true}
                  mozAllowFullScreen={true}
                  className="rounded-lg shadow-md"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default Live;
