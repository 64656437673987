// src/redux/actions/newsActions.js
import { fetchNewsStart, fetchNewsSuccess, fetchNewsFailure } from '../reducers/newsSlice';
import apiService from '../api-service/apiService';

export const fetchNewsList = (forGuest) => async (dispatch, getState) => {
  dispatch(fetchNewsStart());

  try {
    const { userData } = getState().user;
    const news = await apiService.fetchNewsList(forGuest, userData?.identityToken, userData?.id);
    dispatch(fetchNewsSuccess(news));
    console.log(news)
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || // Server-provided message
      error.message || // General error message
      'An error occurred while fetching news.'; // Fallback message
    console.log(error);
    dispatch(fetchNewsFailure(errorMessage));
  }
};